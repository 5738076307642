import { PublicKey, Transaction, Connection, ConnectionConfig } from '@solana/web3.js';
import { Auth, UserInfo, LoginOptions, isParticleDev } from '@particle-network/auth';
import bs58 from 'bs58';
import { Buffer } from 'buffer';
import { ISolanaWallet } from './types';
import { EventEmitter } from 'events';

export class SolanaWallet implements ISolanaWallet {
    public readonly isParticleNetwork = true;

    name = 'Particle';
    url = 'https://particle.network';
    icon = 'https://static.particle.network/wallet-icons/Particle.png';

    private _publicKey: PublicKey | null;

    private _connecting: boolean;

    private events = new EventEmitter();

    constructor(private auth: Auth) {
        this.auth = auth;
        this._connecting = false;

        const userInfo = this.auth.userInfo();
        if (userInfo) {
            const wallet = userInfo.wallets.find((w) => w.chain_name === 'solana' && w.public_address.length > 0);
            if (wallet) {
                this._publicKey = new PublicKey(wallet.public_address);
            } else {
                this._publicKey = null;
            }
        } else {
            this._publicKey = null;
        }

        this.auth.on('connect', (userInfo: UserInfo) => {
            const wallet = userInfo.wallets.find((w) => w.chain_name === 'solana' && w.public_address.length > 0);
            if (wallet) {
                this._publicKey = new PublicKey(wallet.public_address);
                this.events.emit('connect', this._publicKey);
            }
        });
        this.auth.on('disconnect', () => {
            this._publicKey = null;
            this.events.emit('disconnect');
        });
        if (typeof window !== 'undefined' && window.particle) {
            window.particle.solanaWallet = this;
        }
    }

    on(event: string, listener: any): void {
        this.events.on(event, listener);
    }

    once(event: string, listener: any): void {
        this.events.once(event, listener);
    }

    off(event: string, listener: any): void {
        this.events.off(event, listener);
    }

    removeListener(event: string, listener: any): void {
        this.events.removeListener(event, listener);
    }

    get connecting(): boolean {
        return this._connecting;
    }

    get connected(): boolean {
        return this._publicKey !== null;
    }

    get publicKey(): PublicKey | null {
        return this._publicKey;
    }

    public async connect(config?: LoginOptions): Promise<void> {
        try {
            this._connecting = true;
            let wallet = this.auth.wallet();
            if (wallet) {
                this._publicKey = new PublicKey(wallet.public_address);
                return Promise.resolve();
            }
            await this.auth.login(config);
            wallet = this.auth.wallet();
            if (wallet) {
                this._publicKey = new PublicKey(wallet.public_address);
                return Promise.resolve();
            } else {
                return Promise.reject('wallet create failed');
            }
        } catch (e) {
            return Promise.reject(e);
        } finally {
            this._connecting = false;
        }
    }

    public async disconnect(): Promise<void> {
        await this.auth.logout();
        this._publicKey = null;
        return Promise.resolve();
    }

    public async signTransaction(transaction: Transaction): Promise<Transaction> {
        const signature = await this.auth.sign(
            'signTransaction',
            bs58.encode(transaction.serialize({ requireAllSignatures: false, verifySignatures: false }))
        );
        return Transaction.from(Buffer.from(signature, 'base64'));
    }

    public async signAllTransactions(transactions: Transaction[]): Promise<Transaction[]> {
        const signatures = await this.auth.signAllTransactions(
            transactions.map((tx) =>
                bs58.encode(tx.serialize({ requireAllSignatures: false, verifySignatures: false }))
            )
        );
        return signatures.map((signed: string) => Transaction.from(Buffer.from(signed, 'base64')));
    }

    public async signAndSendTransaction(transaction: Transaction): Promise<string> {
        return this.auth.sendTransaction(
            bs58.encode(transaction.serialize({ requireAllSignatures: false, verifySignatures: false }))
        );
    }

    public async signMessage(message: Uint8Array): Promise<Uint8Array> {
        const signature = await this.auth.sign('signMessage', bs58.encode(message));
        return Buffer.from(signature, 'base64');
    }

    public getConnection(config?: ConnectionConfig): Connection {
        const url = isParticleDev()
            ? 'https://rpc-debug.particle.network/solana'
            : 'https://rpc.particle.network/solana';
        return new Connection(
            `${url}?chainId=${this.auth.chainId()}&projectUuid=${this.auth.config.projectId}&projectKey=${
                this.auth.config.clientKey
            }`,
            {
                commitment: config?.commitment,
                wsEndpoint: config?.wsEndpoint,
                httpHeaders: {
                    Authorization: this.auth.basicCredentials(),
                },
                fetch: config?.fetch,
                fetchMiddleware: config?.fetchMiddleware,
                disableRetryOnRateLimit: config?.disableRetryOnRateLimit,
                confirmTransactionInitialTimeout: config?.confirmTransactionInitialTimeout,
            }
        );
    }
}
