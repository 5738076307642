import { v4 } from 'uuid';
import { sha256 } from 'hash.js';

export enum AcitveLoginType {
    PARTICLE = 'particle',
    PRIVATE_KEY = 'private_key',
    METAMASK = 'metamask',
    RAINBOW = 'rainbow',
    TRUST = 'trust',
    IM_TOKEN = 'im_token',
    BIT_KEEP = 'bit_keep',
    PHANTOM = 'phantom',
    OTHER = 'other',
}

export enum ActiveAction {
    SIGN = 'sign',
    LOGIN = 'login',
    OPEN = 'open',
    OPEN_WALLET = 'open_wallet',
}
export interface BiOptions {
    sdk_api_domain: string;
    device_id: string;
    sdk_version: string;
    project_config: {
        project_uuid: string;
        project_key: string;
        project_app_uuid: string;
    };
    auth: {
        username: string;
        password: string;
    };
}

export interface SendActiveParams {
    chain_id: number;
    identity: string;
    login_type: AcitveLoginType;
    action: ActiveAction;
    wallet_address: string;
    user_info?: string;
}

export class BI {
    private options: BiOptions;

    constructor(options: BiOptions) {
        this.options = options;
    }

    active(params: SendActiveParams) {
        const auth = this.options.auth;

        const macKey = sha256()
            .update(auth.username + auth.password)
            .digest('hex')
            .toLowerCase();

        const timestamp: number = Math.round(new Date().getTime() / 1000);
        const randomStr: string = v4();
        const { project_uuid, project_key, project_app_uuid } = this.options.project_config;

        const queryParams = {
            timestamp,
            random_str: randomStr,
            project_app_uuid: project_app_uuid,
            projectUuid: project_uuid,
            projectKey: project_key,
            mac_key: macKey,
        };

        const headers = {
            type: 'application/json',
            'Content-Type': 'application/json',
        };

        let url = this.options.sdk_api_domain + '/active?';
        Object.keys(queryParams).forEach((key) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //  @ts-ignore
            url += `${key}=${encodeURI(queryParams[key])}&`;
        });

        // const blob = new Blob([JSON.stringify(params)], headers);
        // return navigator.sendBeacon(url.slice(0, -1), blob);

        fetch(url.slice(0, -1), {
            method: 'post',
            headers,
            body: JSON.stringify(params),
        })
            .then((response) => response.json())
            .then((response) => {
                console.log(response);
            })
            .catch((error) => {
                console.log('active log error', error);
            });
    }
}
